import {
  BillingRequestResource,
  ConsentType,
  SourceCurrencyEnum as Currency,
} from "@gocardless/api/dashboard/types";
import {
  Box,
  ColorPreset,
  useTheme,
  Text,
  FontWeight,
  Tooltip,
  Separator,
} from "@gocardless/flux-react";
import { Scheme } from "src/common/scheme";
import { getDisplayedConsentInfo } from "src/common/scheme-translations/DisplayedConsentInformation/getDisplayedConsentInfo";
import Amount from "src/legacy-ui/amount";

interface StandingDebitExplainerProps {
  billingRequest: BillingRequestResource;
}
export const StandingDebitExplainer = ({
  billingRequest,
}: StandingDebitExplainerProps) => {
  const { theme } = useTheme();
  const consentType = billingRequest.mandate_request
    ?.consent_type as ConsentType;

  if (!billingRequest.payment_request) {
    return null;
  }
  const { toolTipContent, legalDisclaimer, consentTypeContentText } =
    getDisplayedConsentInfo(
      billingRequest.mandate_request?.scheme as Scheme,
      consentType
    );

  const paymentAmount = billingRequest.payment_request.amount ? (
    <Amount
      currency={billingRequest.payment_request.currency as Currency}
      amountInPence={parseInt(billingRequest.payment_request.amount.toString())}
      simple
      weight={FontWeight.Bold}
    />
  ) : undefined;

  const inlineConsentTypeWithTooltip = (
    <Tooltip message={toolTipContent}>
      {(triggerProps) => (
        <Text
          {...triggerProps}
          size={2}
          decoration="underline"
          color={ColorPreset.TextOnLight_01}
          weight={FontWeight.Bold}
        >
          {consentTypeContentText}
        </Text>
      )}
    </Tooltip>
  );
  // TODO: Add translations
  return (
    <Box
      style={{
        borderRadius: `0 0 ${theme.spacing(1)} ${theme.spacing(1)}`,
        border: "1px solid",
        borderTop: "none",
        borderColor: theme.color(ColorPreset.BorderOnLight_04),
        background: theme.color(ColorPreset.BackgroundLight_01),
        padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
      }}
    >
      <Box>
        {paymentAmount ? (
          <Text size={2} color={ColorPreset.TextOnLight_01}>
            You are setting up a {inlineConsentTypeWithTooltip}, and your first
            authorized debit in the amount of {paymentAmount && paymentAmount}{" "}
            will be taken within a few days of you confirming this
            authorization.
          </Text>
        ) : (
          <Text size={2} color={ColorPreset.TextOnLight_01}>
            You are setting up a {inlineConsentTypeWithTooltip}, and your first
            authorized debit will be taken within a few days of you confirming
            this authorization.
          </Text>
        )}
        {legalDisclaimer && (
          <>
            <Separator spacing={[[1.5, 0]]} />
            {legalDisclaimer({
              space: 0.5,
              textSize: 3,
              color: ColorPreset.TextOnLight_01,
            })}
          </>
        )}
      </Box>
    </Box>
  );
};
